<template>
  <div>
    <About ref="about"/>
    <AssignCheckedStudies ref="assignCheckedStudies"/>
    <DownloadCheckedStudies ref="downloadCheckedStudies"/>
    <MoveCheckedStudies ref="moveCheckedStudies"/>
    <ImportStudy ref="importStudy"/>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="false"/>
    <WorklistSettings ref="worklistSettingsDialog"/>
    <b-modal ref="confirmDeleteWorklistDialog" title="Delete Worklist Filter"
      header-bg-variant="info" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="deleteWorklist">
      <span>Are you sure you want to delete the {{worklistName}} filter?</span>
    </b-modal>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand class="d-none d-sm-block" href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-navbar-nav class="ml-1">
        <b-dropdown variant="info" title="Worklists" :size="buttonSize">
          <template #button-content>
            <b-icon icon="bookmark"></b-icon> <span class="d-none d-sm-inline">{{worklistName}}</span>
          </template>
          <b-dropdown-text class="small">Last&nbsp;Updated:&nbsp;{{refreshTime.replaceAll(' ', '&nbsp;')}}</b-dropdown-text>
          <b-dropdown-item @click="refreshWorklist"><b-icon icon="arrow-clockwise"></b-icon> Refresh</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-for="w in worklistNames" v-bind:key="w" @click="changeWorklist">
            <b-icon v-if="w==defaultWorklistName" icon="bookmark-star"/>
            <b-icon v-else icon="bookmark"/> 
            <span v-if="w==worklistName"><strong> {{ w }}</strong></span>
            <span v-if="w!=worklistName"> {{ w }}</span>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="addWorklist"><b-icon icon="bookmark-plus"></b-icon> Add New</b-dropdown-item>
          <b-dropdown-item @click="editWorklist"><b-icon icon="pencil"></b-icon> Edit {{worklistName}}</b-dropdown-item>
          <b-dropdown-item :disabled="deleteWorklistDisabled" @click="confirmDeleteWorklist"><b-icon icon="trash"></b-icon> Delete {{worklistName}}</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="!isRefreshing">
        <b-badge class="ml-2" variant="secondary" show :size="buttonSize" :title="'Last Updated: '+refreshTime">{{ worklistCount }} <span class="d-none d-sm-inline">Exams</span></b-badge>
        <b-badge v-if="!refreshFlag" class="ml-1" variant="warning" title="Latest Refresh Failed"><b-icon icon="exclamation-triangle"></b-icon></b-badge>
      </b-navbar-nav>
      <b-navbar-nav v-if="isRefreshing">
        <b-spinner class="ml-2" label="Loading..." variant="info"></b-spinner>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-badge v-if="count>=queryLimit" class="ml-2 d-none d-sm-block" variant="warning">Worklist may be truncated, use a more specific filter.</b-badge>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button class="d-none d-sm-inline" v-if="canMoveStudies" variant="secondary" title="Move Selected Studies to a Different Group/Tenant" @click="moveStudies()" :disabled="canMoveStudiesDisabled">
          <b-iconstack>
            <b-icon stacked icon="plus-circle" shift-v="5" shift-h="-6"></b-icon>
            <b-icon stacked icon="building" shift-v="-3" shift-h="4"></b-icon>
          </b-iconstack>
        </b-button>
        <b-button class="ml-1" v-if="canDownloadStudies" variant="secondary" title="Download Selected Studies" @click="downloadStudies()" :disabled="zeroStudiesChecked">
          <b-icon icon="download"></b-icon>
        </b-button>
        <b-button class="ml-1" v-if="canAssignStudies" variant="secondary" title="Assign Selected Studies" @click="assignStudies()" :disabled="zeroStudiesChecked">
          <b-icon icon="person-check"></b-icon>
        </b-button>
        <b-dropdown class="ml-1" title="Functions" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="tools"></b-icon>
          </template>
          <b-dropdown-item v-if="isAdmin" @click="openAdministrativeConsole"><b-icon icon="terminal"></b-icon> Administrative Console</b-dropdown-item>
          <b-dropdown-item v-if="isAdmin" @click="openKeycloakConsole"><b-icon icon="key"></b-icon> Keycloak Console</b-dropdown-item>
          <b-dropdown-item @click="openLocalStudy"><b-icon icon="file-medical"></b-icon> Open Local DICOM Study</b-dropdown-item>
          <b-dropdown-item v-if="canUploadStudy" @click="importStudy"><b-icon icon="cloud-arrow-up"></b-icon> Import Local Study</b-dropdown-item>
          <b-dropdown-item v-if="canAssignStudies" @click="assignStudies" :disabled="zeroStudiesChecked">
            <b-icon icon="person-check"></b-icon> Assign Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canDownloadStudies" @click="downloadStudies" :disabled="zeroStudiesChecked">
            <b-icon icon="download"></b-icon> Download Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canMoveStudies" @click="moveStudies" :disabled="canMoveStudiesDisabled">
            <b-iconstack>
              <b-icon stacked icon="plus-circle" shift-v="5" shift-h="-8"></b-icon>
              <b-icon stacked icon="building" shift-v="-3" shift-h="2"></b-icon>
            </b-iconstack> Move Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canDoQuerySearch" @click="mgmtReports"><b-icon icon="table"></b-icon> Management Reports</b-dropdown-item>
          <b-dropdown-item @click="about"><b-icon icon="info-circle"></b-icon> About</b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="ml-1" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{user}}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item>
          <b-dropdown-item @click="userHistory"><b-icon icon="card-list"></b-icon> History</b-dropdown-item>
          <b-dropdown-item @click="profile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
          <b-dropdown-item @click="logout"><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>  
</template>
<script>
import broadcast from '../common/broadcast'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import About from './About.vue'
import AssignCheckedStudies from './AssignCheckedStudies.vue'
import DownloadCheckedStudies from './DownloadCheckedStudies.vue'
import ImportStudy from './ImportStudy.vue'
import MoveCheckedStudies from './MoveCheckedStudies.vue'
import UserSettings from './UserSettings.vue'
import WorklistSettings from './WorklistSettings.vue'

export default {
  components: {
    About,
    AssignCheckedStudies,
    DownloadCheckedStudies,
    ImportStudy,
    MoveCheckedStudies,
    UserSettings,
    WorklistSettings
  },
  data() {
    return {
      buttonSize: "sm"
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    canAssignStudies() {
      return (this.groupsForAssignStudy.length > 0)
    },
    canDownloadStudies() {
      return (this.groupsForDownloadStudy.length > 0)
    },
    zeroStudiesChecked() {
      return (this.$store.state.checkedStudies.length == 0)
    },
    canUploadStudy() {
      var can_upload_studies = false
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          this.$log.debug(groupsFromToken[i]+"="+permissions.hasPermission(groupsFromToken[i], permissions.CAN_UPLOAD_STUDY))
          can_upload_studies |= permissions.hasPermission(groupsFromToken[i], permissions.CAN_UPLOAD_STUDY)
        }
      }
      catch {
        can_upload_studies = false
      }
      return can_upload_studies
    },
    canMoveStudies() {
      return false // +TODO+ re-enable when move refactored to share //(this.groupsForMoveStudy.length > 0)
    },
    canMoveStudiesDisabled() {
      return (this.$store.state.checkedStudies.length == 0)
    },
    canDoQuerySearch() {
      var can_do_query_search = false
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          can_do_query_search |= permissions.hasPermission(groupsFromToken[i], permissions.CAN_DO_QUERY_SEARCH)
        }
      }
      catch {
        can_do_query_search = false
      }
      return can_do_query_search
    },
    count() {
      return this.$store.state.worklist.length
    },
    worklistCount() {
      return this.$store.state.worklistCount
    },
    defaultWorklistName() {
      return this.$store.getters.defaultWorklistName
    },
    deleteWorklistDisabled() {
      return (this.worklistName == this.$store.getters.defaultWorklistName)
    },
    groupsForAssignStudy() {
      var groupsForUser = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          if (permissions.hasPermission(groupsFromToken[i], permissions.CAN_ASSIGN_STUDIES)) {
            groupsForUser.push(groupsFromToken[i].substring(1))
          }
        }
      }
      catch {
        groupsForUser = []
      }
      return groupsForUser
    },
    groupsForDownloadStudy() {
      var groupsForUser = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          if (permissions.hasPermission(groupsFromToken[i], permissions.CAN_DOWNLOAD_STUDY)) {
            groupsForUser.push(groupsFromToken[i].substring(1))
          }
        }
      }
      catch {
        groupsForUser = []
      }
      return groupsForUser
    },
    groupsForMoveStudy() {
      var groupsForUser = []
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed['groups']
        for (var i=0; i<groupsFromToken.length; i++) {
          if (permissions.hasPermission(groupsFromToken[i], permissions.CAN_UPDATE_STUDY_TENANT)) {
            groupsForUser.push(groupsFromToken[i].substring(1))
          }
        }
      }
      catch {
        groupsForUser = []
      }
      return groupsForUser
    },
    queryLimit() {
      return this.$store.state.queryLimit
    },
    isAdmin() {
      return permissions.isAdmin()
    },
    isRefreshing() {
      return this.$store.state.worklistRefreshing
    },
    refreshFlag() {
      return this.$store.state.worklistRefreshFlag
    },
    refreshTime() {
      return this.$store.state.worklistRefreshTime
    },
    worklistNames() {
      return this.$store.state.worklistNames
    },
    worklistName() {
      return this.$store.state.worklistName
    },
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    }
  },
  watch:{
    worklistName(/*newVal, oldVal*/) {
      this.$store.commit('changeNextStudyUid', null)
      this.$store.commit('changePreviousStudyUid', null)
      let payload = {
        nextStudyUid: null,
        previousStudyUid: null,
        window_uid: this.$store.state.uid
      }
      broadcast.postMessage(broadcast.WORKLIST_NEXT_PREV_MSG, payload)
    }
  },
  methods: {
    addWorklist() {
      this.$refs.worklistSettingsDialog.show('', true)
    },
    assignStudies() {
      this.$refs.assignCheckedStudies.show(this.groupsForAssignStudy)
    },
    downloadStudies() {
      this.$refs.downloadCheckedStudies.show(this.groupsForDownloadStudy)
    },
    mgmtReports() {
      this.$router.replace('mgmt_reports')
    },
    moveStudies() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', '')
        this.$refs.moveCheckedStudies.show(this.groupsForMoveStudy)
      }
    },
    confirmDeleteWorklist() {
      this.$refs['confirmDeleteWorklistDialog'].show()
    },
    deleteWorklist() {
      this.$store.commit("removeWorklistFilter", this.$store.state.worklistName)
      var worklistName = this.$store.getters.defaultWorklistName
      this.$store.commit("changeWorklistName", worklistName)

      this.$log.debug("Updating cache for worklistSettings")
      webServices.updateUserSetting("worklistSettings", this.$store.state.worklistSettings)
      .then(response => {
        this.$log.debug(response)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
    },
    editWorklist() {
      this.$refs.worklistSettingsDialog.show(this.$store.state.worklistName, true)
    },
    changeWorklist(event) {
      try {
        this.$store.commit('changeActiveComponent', "")
        this.$store.commit('changeSelectedStudyUid', "")
        this.$store.commit('changeWorklist', [])
        this.$store.commit('changeWorklistName', event.currentTarget.innerText.substring(1))
        this.refreshWorklist()
      }
      catch(err) {
        this.$log.error('Failed to refresh worklist: '+err);
      }
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
    openAdministrativeConsole() {
      // Open in a new window/tab.
      // +TODO+ Replace adminUrl logic to not depend on web services location and hard-coded port.
      //
      var adminUrl = this.$store.state.webServicesBaseUrl.replace("/web_services", ":8443/dcm4chee-arc/ui2")
      window.open(adminUrl, "pacsadmin_console");
    },
    openKeycloakConsole() {
      // Open in a new window/tab.
      // +TODO+ Replace adminUrl logic to not depend on web services location and hard-coded port.
      //
      var adminUrl = this.$store.state.webServicesBaseUrl.replace("/web_services", "/auth/admin/dcm4che/console")
      window.open(adminUrl, "keycloak_console");
    },
    importStudy() {
      this.$refs.importStudy.show()
    },
    openLocalStudy() {
      this.$router.replace('viewer_local')
    },
    about() {
      this.$refs.about.show()
    },
    logout() {
      this.$router.replace('logout')
    },
    userHistory() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'UserHistory')
      }
    },
    userSettings() {
      this.$refs.userSettingsDialog.show()
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    },
    refreshWorklist: function(/*event*/) {
      this.$store.commit("changeSelectedStudyUid", "")
      webServices.readWorklist()
    }
  }
}    
</script>
<style scoped>
</style>